export const trainingTemplateEn = {
  trainingTemplates: {
    title: "Training templates",
    toolTips: {
      edit: "Edit training template {trainingTemplate}",
      delete: "Delete training template {trainingTemplate}",
    },
    editTitle: "Edit training template",
    newTitle: "New training template",
    addTrainingTemplate: "Add training template",

    errors: {
      notFound: "Training template {id} not found",
      notYourRole: "Cant access data for training template {id}",
      nameExists: "Training template named `{name}` already exists",
      commonGET_TRAINING_TEMPLATE_BY_ID:
        "Error retrieving training template data",
      commonDELETE_TRAINING_TEMPLATE_BY_ID: "Error deleting training template",
      commonDELETE_TRAINING_TEMPLATES: "Error deleting training templates",
      cantGetTrainingTemplates: "Failed to fetch training templates data",
      cantGetClientTrainingTemplates:
        "Failed to fetch client training templates data",
    },
    client: {
      playVideo: "Play video",
      title: "Training 2.0",
      titleClient: "My training",
      trainingPaused: "Training is paused",
      trainingReady: "Your training is ready",
      trainingNotPaid:
        "We are currently awaiting the payment to be credited to our account. Once the payment has been successfully processed, the product will be immediately available.",
      trainingAssigned: "Training is waiting to start",
      trainingStartAt: "Training is scheduled to begin on {date}.",
      startTraining: "Start training",
      pauseTraining: "Pause training",
      exerciseLength: "Exercise length",
      bodyPart: "Body part",
      exerciseExecution: "Exercise execution",
      accessories: "Accessories",
      noAccessories: "no accessories",
      description: "Exercise description",
      markAsFinished: "Not finished yet",
      markAsUnfinished: "Finished",
    },
    form: {
      addTrainingTemplate: "Add training template",
      name: "Name",
      description: "Desription",
      period: "Period of change",
      type: "Type",
      status: "Status",
      client: "Client",
      assigned_at: "Assigned at",
      length: "Length",
      remaining_periods: "Remaining periods",
      company: "Companies",
      programs: "Programs",
      timeline: "Timeline",
      trainingTemplateChanged: "Training template was stored",
      trainingTemplateChangedFail: "Failed to store training template",
      trainingTemplateAdded: "New training template was created",
      trainingTemplateAddFail: "Failed to add new training template",
      newTrainingTemplateTimeline:
        "You must save the template to enter items in the timeline.",
    },
    exercise: {
      form: {
        unit: "Unit",
        unit_type: "Unit type",
        counting_type: "Counting",
        body_part: "Body part",
        exerciseSelection: "Exercise selection",
        parameters: "Parameters",
        value: "Value",
        exercise: "Exercise",
        exercise_execution: "Execution",
        tools: "Tools / Games",
        accessories: "Accessories",
      },
      modal: {
        newItem: "New exercise",
      },
    },
    counting_types: {
      time_increment: "Increment",
      time_decrement: "Decrement",
      time_hold: "Hold",
      "activity-pause": "Activity/Pause",
    },
    periods: {
      countable: {
        day: "Nr. of days",
        week: "Nr. of weeks",
        month: "Nr. of months",
        year: "Nr. of years",
      },
      day: "day",
      week: "week",
      month: "month",
      year: "year",
      exerciseCount: "Exercise count",
      copy: "Copy",
      new: "New",
      actions: {
        edit: "Edit item",
        add: "Add item",
        clone: "Clone item",
        delete: "Delete item",
      },
      modal: {
        newItem: "New item",
        form: {
          name: "Name",
          exercises: "Exercises",
          required: {
            name: "Name is a required field",
          },
        },
      },
    },
    statuses: {
      draft: "Draft",
      template: "Template",
      assigned: "Assigned",
      paused: "Paused",
      finished: "Finished",
      active: "Active",
      waiting: "Waiting",
    },
    table: {
      filter: "Filter",
      header: {
        name: "Name",
        description: "Desription",
        period: "Period of change",
        type: "Type",
        status: "Status",
        client: "Client",
        assigned_at: "Assigned at",
        length: "Length",
        remaining_periods: "Remaining periods",
        company: "Companies",
        programs: "Programs",
        actions: "Actions",
      },
      deleteTrainingTemplateQuestion:
        "Do you really want to delete  training template <strong>{trainingTemplate}</strong>?",
      searchTrainingTemplates: "Search training templates",
      trainingTemplateDeleted:
        "TrainingTemplate {trainingTemplate} was deleted",
      trainingTemplatesDeleted: "Training templates were deleted",
      noRecords: "No training templates found",
    },
  },
};
