import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router/clean";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import Toast, { PluginOptions } from "vue-toastification";
import "vue-toastification/dist/index.css";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import cs from "element-plus/es/locale/lang/cs";
import sk from "element-plus/es/locale/lang/sk";
import en from "element-plus/es/locale/lang/en";

import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
import "bootstrap";
import axios from "axios";
import { filters } from "@/core/helpers/filters";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import VueTelInput from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";

import Vue3VideoPlayer from "@cloudgeek/vue3-video-player";
import VideoBackground from "vue-responsive-video-background-player";
import "@cloudgeek/vue3-video-player/dist/vue3-video-player.css";
import { File } from "@/core/plugins/quill/File";
import Quill from "quill/dist/quill";
import FileContentIcon from "@/components/widgets/training/FileContentIcon.vue";
import PusherPlugin from "./plugins/pusher";

import Vue3ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";
import JsonEditorVue from "json-editor-vue3";
import ColorWordMatchGame from "@/components/tools/_games/ColorWordMatchGame.vue";
import ColorWordMatchV2Game from "@/components/tools/_games/ColorWordMatchV2Game.vue";
import PickUniqueObjectGame from "@/components/tools/_games/PickUniqueObjectGame.vue";
import PickUniqueObjectV2Game from "@/components/tools/_games/PickUniqueObjectV2Game.vue";
import RememberTheGridMultipleGame from "@/components/tools/_games/RememberTheGridMultipleGame.vue";
import ReactionTimeGame from "@/components/tools/_games/ReactionTimeGame.vue";
import GoNoGoGame from "@/components/tools/_games/GoNoGoGame.vue";
import RememberTheGridGame from "@/components/tools/_games/RememberTheGridGame.vue";
import RememberTheGridV2Game from "@/components/tools/_games/RememberTheGridV2Game.vue";
import CountingGame from "@/components/tools/_games/CountingGame.vue";
import PressAllGame from "@/components/tools/_games/PressAllGame.vue";
import PressAllMultipleGame from "@/components/tools/_games/PressAllMultipleGame.vue";
import ReactionTimeV2Game from "@/components/tools/_games/ReactionTimeV2Game.vue";
import RememberTheGridMultipleV2Game from "@/components/tools/_games/RememberTheGridMultipleV2Game.vue";

const locale = en;
if (localStorage.lang === "cs") {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  locale.el.datepicker = cs.el.datepicker;
}
if (localStorage.lang === "sk") {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  locale.el.datepicker = sk.el.datepicker;
}
locale.el.datepicker.year = "";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(ElementPlus, {
  namespace: "el",
  locale,
});

const options: PluginOptions = {
  // You can set your default options here
};

const VueTelInputOptions = {};
app.use(VueTelInput, VueTelInputOptions);
app.use(Toast, options);
app.use(Vue3VideoPlayer, {
  lang: "en",
});
const apiKey = process.env.VUE_APP_PUSHER_API_KEY;
app.use(PusherPlugin, {
  apiKey,
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
});
axios.interceptors.request.use((config) => {
  config.url = config.url?.replace(/\/$/, "");
  return config;
});

ApiService.init(app);
// MockAdapter.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.component("QuillEditor", QuillEditor);
app.component("file-content-icon", FileContentIcon);
app.component("video-background", VideoBackground);

// Game components
app.component("ColorWordMatchGame", ColorWordMatchGame);
app.component("ColorWordMatchV2Game", ColorWordMatchV2Game);
app.component("PickUniqueObjectGame", PickUniqueObjectGame);
app.component("PickUniqueObjectV2Game", PickUniqueObjectV2Game);
app.component("RememberTheGridGame", RememberTheGridGame);
app.component("RememberTheGridV2Game", RememberTheGridV2Game);
app.component("RememberTheGridMultipleGame", RememberTheGridMultipleGame);
app.component("ReactionTimeGame", ReactionTimeGame);
app.component("GoNoGoGame", GoNoGoGame);
app.component("CountingGame", CountingGame);
app.component("PressAllGame", PressAllGame);
app.component("PressAllMultipleGame", PressAllMultipleGame);
app.component("ReactionTimeV2Game", ReactionTimeV2Game);
app.component("RememberTheGridMultipleV2Game", RememberTheGridMultipleV2Game);

app.config.globalProperties.$filters = filters;
app.mount("#app");
app.use(Vue3ColorPicker);
app.use(JsonEditorVue);
File.blotName = "fileBlob";
File.tagName = "span";
Quill.register(File);
const icons = Quill.import("ui/icons");
icons["code-block"] =
  '<span style="font-size: 1.3em;\n' +
  "    position: relative;\n" +
  '    bottom: 3px;" class="bi bi-file-arrow-down text-white bold"></span>';
