export const trainingTemplateSk = {
  trainingTemplates: {
    title: "Šablony tréninků",
    toolTips: {
      edit: "Upravit šablonu tréninku {trainingTemplate}",
      delete: "Odstranit šablonu tréninku {trainingTemplate}",
    },
    editTitle: "Upravit šablonu tréninku",
    newTitle: "Nová šablona tréninku",
    addTrainingTemplate: "Přidat šablonu tréninku",
    errors: {
      notFound: "Šablona tréninku id {id} nebyla nalezena",
      nameExists: "Název šablony `{name}` již existuje",
      notYourRole: "Nemáte práva zobrazit šablonu tréninku id {id}",
      commonGET_TRAINING_TEMPLATE_BY_ID:
        "Chyba při získávání dat o šablonách tréninku",
      commonDELETE_TRAINING_TEMPLATE_BY_ID:
        "Chyba při odstraňování šablony tréninku",
      commonDELETE_TRAINING_TEMPLATES:
        "Nepodařilo se odstranit šablonu tréninku",
      cantGetTrainingTemplates: "Chyba při získávání dat o šablonách tréninku",
      cantGetClientTrainingTemplates:
        "Chyba při získávání dat o šablonách tréninku pro klienta",
    },
    client: {
      playVideo: "Přehrát video",
      title: "Trénink 2.0",
      titleClient: "Můj trénink",
      trainingPaused: "Trénink je pozastavený",
      trainingReady: "Váš trénink je připravený",
      trainingNotPaid:
        "V současné době čekáme na připsání platby na náš účet. Jakmile bude platba úspěšně zpracována, produkt bude okamžitě k dispozici.",
      trainingAssigned: "Trénink čeká na spuštění",
      trainingStartAt: "Začátek tréninku je plánován na {date}.",
      startTraining: "Spustit trénink",
      pauseTraining: "Pozastavit trénink",
      exerciseLength: "Délka cvičení",
      bodyPart: "Část těla",
      exerciseExecution: "Způsob provádění",
      accessories: "Pomůcky",
      noAccessories: "bez pomůcek",
      description: "Popis cvičení",
      markAsFinished: "Neodcvičeno",
      markAsUnfinished: "Odcvičeno",
    },
    form: {
      addTrainingTemplate: "Přidat šablonu tréninku",
      name: "Název",
      description: "Popis",
      period: "Jednotka změny",
      type: "Typ",
      status: "Stav",
      client: "Klient",
      assigned_at: "Přiřazeno",
      length: "Délka",
      remaining_periods: "Zbývá jednotek",
      company: "Firmy",
      programs: "Produkty",
      timeline: "Časová osa",
      trainingTemplateChanged: "Šablona tréninku byla upravena",
      trainingTemplateChangedFail: "Nepodařilo se upravit šablonu tréninku",
      trainingTemplateAdded: "Nová šablona tréninku byla vytvořena",
      trainingTemplateAddFail: "Nepodařilo se vytvořit novou šablonu tréninku",
      newTrainingTemplateTimeline:
        "Pro zadávání položek do časové osy musíte šablonu uložit.",
    },
    exercise: {
      form: {
        unit: "Jednotka",
        unit_type: "Druh jednotky",
        body_part: "Část těla",
        counting_type: "Počítání",
        exerciseSelection: "Výběr cviku",
        parameters: "Parametry",
        value: "Hodnota",
        exercise: "Cvik",
        exercise_execution: "Provádění",
        tools: "Nástoje / hry",
        accessories: "Pomůcky",
      },
      modal: {
        newItem: "Nový cvik",
      },
    },
    counting_types: {
      time_increment: "Přičítání",
      time_decrement: "Odečítání",
      time_hold: "Výdrž",
      "activity-pause": "Aktivita/Pauza",
    },
    periods: {
      countable: {
        day: "Počet dnů",
        week: "Počet týdnů",
        month: "Počet měsíců",
        year: "Počet roků",
      },
      day: "den",
      week: "týden",
      month: "měsíc",
      year: "rok",
      exerciseCount: "Počet cviků",
      copy: "Kopie",
      new: "Nový",
      actions: {
        edit: "Upravit položku",
        add: "Přidat položku",
        clone: "Duplikovat položku",
        delete: "Odstranit položku",
      },
      modal: {
        newItem: "Nová položka",
        form: {
          name: "Název",
          exercises: "Cviky",
          required: {
            name: "Název položky nesmí být prázdný",
          },
        },
      },
    },
    statuses: {
      draft: "Rozpracováno",
      template: "Šablona",
      assigned: "Přiřazeno",
      paused: "Pozastaveno",
      finished: "Ukončeno",
      active: "Aktivní",
      waiting: "Ve frontě",
    },
    table: {
      filter: "Filtr",
      header: {
        name: "Název",
        description: "Popis",
        period: "Jednotka změny",
        type: "Typ",
        status: "Stav",
        client: "Klient",
        assigned_at: "Přiřazeno",
        length: "Délka",
        remaining_periods: "Zbývá jednotek",
        company: "Firmy",
        programs: "Produkty",
        actions: "Akce",
      },
      deleteTrainingTemplateQuestion:
        "Opravdu chcete odstranit šablonu tréninku <strong>{trainingTemplate}</strong>?",
      searchTrainingTemplates: "Hledat šablonu tréninku",
      trainingTemplateDeleted:
        "Šablona tréninku {trainingTemplate} byla odstraněna",
      trainingTemplatesDeleted: "Šablony tréninku byly odstraněny",
      noRecords: "Nebyly nalezeny žádné šablony tréninku",
    },
  },
};
